import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { EvaIcon } from '@paljs/ui/Icon';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  checkNumber,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  buildQuery,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const GRAPage = () => {
  let dateStr = new Date().getFullYear().toString().substr(-2);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [receiptNumber, setReceiptNumber] = useState(dateStr + '*****');
  const [receiptDate, setReceiptDate] = useState(dateFormat(new Date()));
  const [docNumber, setDocNumber] = useState('');
  const [docDate, setDocDate] = useState(dateFormat(new Date()));
  const [customersSupplier, setCustomersSupplier] = useState('');
  const [customer, setCustomer] = useState('');
  const [customersSort, setCustomersSort] = useState('');
  const [baleCount, setBaleCount] = useState('');
  const [totalMeters, setTotalMeters] = useState(0);
  const [totalKgs, setTotalKgs] = useState(0);
  const [metersBalance, setMetersBalance] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [driverName, setDriverName] = useState('');
  const [driverMobile, setDriverMobile] = useState('');
  const [godownNumber, setGodownNumber] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortName, setSortName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [reWork, setReWork] = useState(false);
  const [storeSorts, setStoreSorts] = useState([]);
  const [linearkg, setLinearkg] = useState(0);
  const [defaultReWork, setDefaultReWork] = useState([{ label: 'No', value: false }]);
  const sampleBoolean = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const [pickId, setPickId] = useState('');

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const [defaultUser, setDefaultUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [defaultSort, setDefaultSort] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getNewReceiptNumber();
    authCheck(authState.authData);
    getCustomers();
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'receiptNumber':
        setReceiptNumber(checkNumber(event.target));
        break;
      case 'receiptDate':
        setReceiptDate(event.target.value);
        break;
      case 'docNumber':
        setDocNumber(event.target.value);
        break;
      case 'docDate':
        setDocDate(event.target.value);
        break;
      case 'customersSupplier':
        setCustomersSupplier(event.target.value);
        break;
      case 'baleCount':
        setBaleCount(checkNumber(event.target));
        break;
      case 'totalMeters':
        setTotalMeters(checkNumber(event.target));
        if (linearkg > 0 && checkNumber(event.target)) {
          setTotalKgs(event.target.value / linearkg);
        }
        break;
      case 'totalKgs':
        setTotalKgs(checkNumber(event.target));
        if (linearkg > 0 && checkNumber(event.target)) {
          setTotalMeters(event.target.value * linearkg);
        }
        break;
      case 'vehicleNumber':
        setVehicleNumber(event.target.value);
        break;
      case 'driverName':
        setDriverName(event.target.value);
        break;
      case 'driverMobile':
        setDriverMobile(checkNumber(event.target, '10.0'));
        break;
      case 'godownNumber':
        setGodownNumber(checkNumber(event.target, '2.0'));
        break;
      case 'remarks':
        setRemarks(event.target.value);
        break;
      case 'reWork':
        setReWork(event.target.value);
        break;
    }
  };

  const clearFormData = (edit = false) => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setReceiptNumber('');
    setReceiptDate(dateFormat(new Date()));
    setDocNumber('');
    setDocDate(dateFormat(new Date()));
    setCustomersSupplier('');
    setBaleCount('');
    setTotalMeters(0);
    setTotalKgs(0);
    setMetersBalance('');
    setVehicleNumber('');
    setDriverName('');
    setDriverMobile('');
    setGodownNumber('');
    setRemarks('');
    setCustomersSort('');
    setCustomer('');
    setDefaultUser([]);
    setDefaultSort([]);
    setPickId('');
    setReWork('');
    setDefaultReWork([{ label: 'No', value: false }]);
    resetRequired();
    setStoreSorts([]);
    setLinearkg(0);
    if (edit === false) {
      getNewReceiptNumber();
    }
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'grey-receipt-advices?populate=*&sort[0]=id:desc&pagination[page]=' + page + '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
      })
      .catch((error) => {
        //return  error ;
      });
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ name: item.name, label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getNewReceiptNumber = () => {
    axios
      .post(
        baseUri() + 'gra/receipt-number',
        {
          data: {
            date: dateFormat(new Date()),
          },
        },
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        setReceiptNumber(res.data.data);
      });
  };

  const getSorts = (cId) => {
    setCustomersSort('');
    setDefaultSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?populate=*&filters[user][id][$eq]=${cId}`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        setStoreSorts(response.data.data);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const userChange = (event) => {
    setCustomer(event.value);
    setCustomerName(event.name);
    setDefaultUser({ label: event.label, value: event.value });
    pullDownReset('customer');
    getSorts(event.value);
    setStoreSorts([]);
    setLinearkg(0);
  };

  const sortChange = (event) => {
    setCustomersSort(event.value);
    setDefaultSort({ label: event.label, value: event.value });
    pullDownReset('customersSort');
    setSortName(event.label);
    let match = [];
    match = storeSorts.find((o, i) => {
      if (o.id == event.value) {
        return true;
      }
    });
    let lkg = match.attributes.sort.data.attributes.linearMeter;
    setLinearkg(lkg);
    if (lkg > 0 && totalMeters > 0) {
      setTotalKgs(totalMeters / lkg);
    } else if (lkg > 0 && totalKgs > 0) {
      setTotalMeters(totalKgs * lkg);
    }
  };

  const handleSampleChange = (event) => {
    setReWork(event.value);
    setDefaultReWork({ label: event.label, value: event.value });
    pullDownReset('reWork');
  };
  const postApi = () => {
    const postData = {
      data: {
        receiptNumber,
        receiptDate,
        docNumber,
        docDate,
        customersSupplier,
        baleCount,
        totalMeters,
        totalKgs,
        metersBalance,
        vehicleNumber,
        driverName,
        driverMobile,
        godownNumber,
        remarks,
        customer,
        customersSort,
        customerName,
        sortName,
        reWork,
      },
    };
    let validate = requireCheck(postData);
    if (!validate) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] === '') {
        delete postData['data'][key];
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'grey-receipt-advices' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          if (error.response.data.error.message === 'This attribute must be unique') {
            hideLoader();
            toast.dismiss();
            toast.error('Receipt Number already taken');
          }
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'grey-receipt-advices', postData, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          if (error.response.data.error.message === 'This attribute must be unique') {
            hideLoader();
            toast.dismiss();
            toast.error('Receipt Number already taken');
          }
          //return  error;
        });
    }
  };

  const editGra = (id) => {
    showLoader();
    clearFormData(true);
    axios
      .get(baseUri() + `grey-receipt-advices/${id}?populate=*`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        response = response.data.data;
        setSave(pageAccess.edit === 1);
        setPickId(response.id);
        setReceiptNumber(response.attributes.receiptNumber);
        setReceiptDate(dateFormat(response.attributes.receiptDate));
        setDocNumber(response.attributes.docNumber);
        setDocDate(dateFormat(response.attributes.docDate));
        setCustomersSupplier(response.attributes.customersSupplier);
        setBaleCount(response.attributes.baleCount);
        setTotalMeters(response.attributes.totalMeters);
        setTotalKgs(response.attributes.totalKgs);
        setMetersBalance(response.attributes.metersBalance);
        setVehicleNumber(response.attributes.vehicleNumber);
        setDriverName(response.attributes.driverName);
        setDriverMobile(response.attributes.driverMobile);
        setGodownNumber(response.attributes.godownNumber);
        setRemarks(response.attributes.remarks);
        setReWork(response.attributes.reWork);
        setDefaultReWork({
          label: response.attributes.reWork ? 'Yes' : 'No',
          value: response.attributes.reWork ? true : false,
        });
        setDefaultSort({
          label: response.attributes.customersSort.data.attributes.sortName,
          value: response.attributes.customersSort.data.id,
        });
        setCustomersSort(response.attributes.customersSort.data.id);
        setDefaultUser({
          label:
            response.attributes.customer.data.attributes.userCode +
            ' - ' +
            response.attributes.customer.data.attributes.name,
          value: response.attributes.customer.data.id,
        });
        setCustomerName(response.attributes.customer.data.attributes.name);
        setCustomer(response.attributes.customer.data.id);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteGra = (id) => {
    const removeGroup = window.confirm('Would you like to delete this GRA?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'grey-receipt-advices/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editGra(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteGra(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Receipt No.',
      selector: (row) => `${row.attributes.receiptNumber}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Date',
      selector: (row) => `${dateFormat(row.attributes.receiptDate)}`,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Customer',
      selector: (row) =>
        `${
          row.attributes.customer.data
            ? row.attributes.customer.data.attributes.userCode + ' - ' + row.attributes.customer.data.attributes.name
            : ''
        }`,
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Sort',
      selector: (row) =>
        `${row.attributes.customersSort.data ? row.attributes.customersSort.data.attributes.sortName : ''}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Bale',
      selector: (row) => `${numFormatter(row.attributes.baleCount)}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'KG',
      selector: (row) => `${numFormatter(row.attributes.totalKgs)}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Meters',
      selector: (row) => `${numFormatter(row.attributes.totalMeters)}`,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Rework',
      selector: (row) => `${row.attributes.reWork ? 'Yes' : 'No'}`,
      sortable: true,
      maxWidth: '5%',
    },
  ];

  const filterColumns = [
    'totalMeters',
    'totalKgs',
    'baleCount',
    'receiptDate',
    'receiptNumber',
    'customer.userCode',
    'customer.name',
    'customersSort.sortName',
    'reWork',
  ];

  return (
    <>
      <SEO title="GRA Master" />
      <Toaster />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> GRA Master</header>
            <CardBody>
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Receipt Number</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="receiptNumber"
                      placeholder="Receipt Number"
                      onChange={(e) => handleChange(e)}
                      value={receiptNumber}
                      className="receiptNumber require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Receipt Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="receiptDate"
                      placeholder="Receipt Date *"
                      onChange={(e) => handleChange(e)}
                      value={receiptDate}
                      className="receiptDate require"
                      readOnly
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Doc. No. <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="docNumber"
                      placeholder="Doc. No. *"
                      onChange={(e) => handleChange(e)}
                      value={docNumber}
                      className="docNumber require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Doc. Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="docDate"
                      placeholder="Doc. Date *"
                      onChange={(e) => handleChange(e)}
                      value={docDate}
                      className="docDate require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Select Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => userChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultUser}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Select Sort <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => sortChange(e)}
                    options={sortOptions}
                    id="customersSort"
                    name="customersSort"
                    placeholder="Select Sort *"
                    value={defaultSort}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customers Supplier</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="customersSupplier"
                      placeholder="Customers Supplier"
                      onChange={(e) => handleChange(e)}
                      value={customersSupplier}
                      className="customersSupplier"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Bale Count <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="baleCount"
                      placeholder="Bale Count"
                      onChange={(e) => handleChange(e)}
                      value={baleCount}
                      className="baleCount require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Total Meters</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="totalMeters"
                      placeholder="Total Meters"
                      onChange={(e) => handleChange(e)}
                      value={totalMeters}
                      className="totalMeters require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Total KG</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="totalKgs"
                      placeholder="Total KG"
                      onChange={(e) => handleChange(e)}
                      value={totalKgs}
                      className="totalKgs"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Vehicle No.</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="vehicleNumber"
                      placeholder="Vehicle No."
                      onChange={(e) => handleChange(e)}
                      value={vehicleNumber}
                      className="vehicleNumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Driver Name</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="driverName"
                      maxLength="20"
                      placeholder="Driver Name"
                      onChange={(e) => handleChange(e)}
                      value={driverName}
                      className="driverName"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Driver Mobile</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="driverMobile"
                      placeholder="Driver Mobile"
                      maxLength="10"
                      onChange={(e) => handleChange(e)}
                      value={driverMobile}
                      className="driverMobile"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Godown No.</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="godownNumber"
                      placeholder="Godown No."
                      onChange={(e) => handleChange(e)}
                      maxLength="2"
                      value={godownNumber}
                      className="godownNumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>GRA Rework</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleSampleChange(e)}
                    options={sampleBoolean}
                    id="reWork"
                    name="reWork"
                    placeholder="GRA Rework?"
                    value={defaultReWork}
                    className="pull-down"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 8 }}>
                  <label>Remarks</label>
                  <Input fullWidth size="Small">
                    <textarea
                      rows={1}
                      onChange={(e) => handleChange(e)}
                      placeholder="Remarks"
                      name="remarks"
                      value={remarks}
                      className="remarks"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button
                    status="Success"
                    disabled={!isSave}
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={postApi}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    status="danger"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={() => clearFormData()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>GRA Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default GRAPage;
